import React from 'react';
import { Helmet } from 'react-helmet';
import { NewRetrospectiveTemplate } from '@we-agile-you/retrospective-app';
import { Layout } from '@we-agile-you/retrospective-app';

export default function NewRetrospective() {
  return (
    <Layout>
      <Helmet title="Start new retrospective" />
      <NewRetrospectiveTemplate />
    </Layout>
  );
}
